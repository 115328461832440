import { createReducer } from '@reduxjs/toolkit';
import {
    getUsersSuccess,
    setUserAction,
    userActionSuccess,
    setUpdatedCredits,
    getUserActionsSuccess,
    getUserChannelsSuccess,
    toggleEnterpriseData, fetchConversionPathsSuccess, setPriority
} from '../actions';
import {User, Pagination, UserAction, UpdatedCredits, Action, ConversionPath} from '../../types';
import { setUserDetailsSuccess, clearUserAction, setUserActionComment, locationChange } from '../actions/index';

const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 20,
  page: 1,
  nextPage: null,
  prevPage: null,
  pagingCounter: 1,
  totalDocs: 0,
  totalPages: 0,
};

const DEFAULT_STATE = {
  users: [] as Array<User>,
  user: {} as User,
  search: '',
  action: {} as UserAction,
  actionInProgress: false,
  actionLoaderMessage: 'Handling the action',
  pagination: DEFAULT_PAGINATION as Pagination,
  actions: [] as Array<Action>,
  actionPagination: DEFAULT_PAGINATION as Pagination,
  updatedCredits: {
    purchasedCredits: 0,
    subscriptionCredits: 0
  } as UpdatedCredits,
  channels: {
    api: 0,
    web: 0,
  },
  enterprise: false as boolean,
  conversionPaths: [] as Array<ConversionPath>,
  updatedPriority: 0,
};

const userReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(locationChange, (state) => {
      state.user = {} as User;
      state.action = {} as UserAction;
      state.updatedCredits = {
        purchasedCredits: 0,
        subscriptionCredits: 0
      } as UpdatedCredits;
    })
    .addCase(getUsersSuccess, (state, action) => {
      const { docs, ...pagination } = action.payload;
      state.users = docs;
      state.pagination = pagination;
    })
    .addCase(getUserActionsSuccess, (state, action) => {
      const { docs, ...pagination } = action.payload;
      state.actions = docs;
      state.actionPagination = pagination;
    })
    .addCase(setUserDetailsSuccess, (state, action) => {
      state.user = action.payload;
    })
    .addCase(setUserAction, (state, action) => {
      state.action = action.payload;
    })
    .addCase(userActionSuccess, (state, action) => {
      state.user = action.payload;
    })
    .addCase(setUserActionComment, (state, action) => {
      state.action.comment = action.payload;
    })
    .addCase(clearUserAction, (state) => {
      state.action = {} as UserAction;
    })
    .addCase(setUpdatedCredits, (state, action) => {
      state.updatedCredits = action.payload;
    })
    .addCase(getUserChannelsSuccess, (state, action) => {
      state.channels = {api: action.payload.api, web: action.payload.web};
    })
    .addCase(toggleEnterpriseData, (state) => {
      state.enterprise = !state.enterprise;
    })
    .addCase(fetchConversionPathsSuccess, (state, action) => {
      state.conversionPaths = action.payload.conversionPaths;
    })
    .addCase(setPriority, (state, action) => {
      state.updatedPriority = action.payload;
    })
);

export default userReducer;
