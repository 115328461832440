import React from 'react';
import {connect} from 'react-redux';
import {RootStateType} from '../../../redux/store';
import {InputNumber} from 'antd';
import {setPriority} from '../../../redux/actions';
import {updatedPriority, userDetails} from "../../../redux/selectors/userSelector";

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const Priority: React.FunctionComponent<Props> = ({
    priority,
    user,
    setPriority}) => {

  return (
    <div className='manage-priority'>
      <p>Priority:</p>
      <InputNumber
        placeholder='Priority'
        min={0}
        max={2}
        defaultValue={user.priority}
        onChange={(x: any) => {
          setPriority(x);
        }}
      />
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  user: userDetails(state),
  priority: updatedPriority(state),
})

const mapDispatch = {
  setPriority
}

export default connect(mapState, mapDispatch)(Priority);