 import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
    isUserSuspended,
    userHasPlan,
    isAccountInactive,
    hasAdminPanelAccess,
    stripeSubscription,
    isStripeSubscriptionCancelled, isEnterpriseAccount, userDetails
} from '../../redux/selectors/userSelector';
import {
    setUserAction,
    manageCredits,
    activateSuspendedAccount,
    sendPasswordResetEmail,
    sendAccountActivationEmail,
    grantAdminPanelAccess,
    revokeAdminPanelAccess,
    cancelStripeSubscription,
    restoreStripeSubscription, activateNewAccount, suspendAccount, toggleEnterprise, managePriority
} from '../../redux/actions';
import { UserAction } from '../../types';
import ManageCredits from './actions/ManageCredits';
 import Priority from "./actions/Priority";

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const ActionMenu: React.FunctionComponent<Props> = ({
  isUserSuspended,
  isAccountInactive,
  userHasPlan,
  hasAdminPanelAccess,
  setUserAction,
  suspendAccount,
  manageCredits,
  activateSuspendedAccount,
  sendPasswordResetEmail,
  sendAccountActivationEmail,
  grantAdminPanelAccess,
  revokeAdminPanelAccess,
  stripeSubscription,
  cancelStripeSubscription,
  restoreStripeSubscription,
  isStripeSubscriptionCancelled,
  activateNewAccount,
  isEnterpriseAccount,
  toggleEnterprise,
  managePriority,
  user
}) => {

  const savePriority = () => {
      managePriority()
  }

  return (
    <div className="user-details-menu">
      {/* TODO: make each button a component */}
      <button
        className="user-details-menu-button red-bg"
        disabled={isUserSuspended}
        onClick={() => {
          setUserAction({
            name: "Suspend account",
            onAccept: suspendAccount,
          } as UserAction);
        }}
      >
        Suspend account
      </button>

      {/* <Tooltip placement="right" title='User has no plan set'> */}
      {/* </Tooltip> */}
      <button
        className="user-details-menu-button"
        disabled={!userHasPlan}
        onClick={() =>
          setUserAction({
            name: "Manage credits",
            Component: ManageCredits,
            onAccept: manageCredits,
          } as UserAction)
        }
      >
        Manage credits
      </button>

      <button
        className="user-details-menu-button"
        disabled={isUserSuspended || isAccountInactive}
        onClick={() => {
          setUserAction({
            name: "Send account activation email",
            onAccept: sendAccountActivationEmail,
          } as UserAction);
        }}
      >
        Send activation email
      </button>

      <button
        className="user-details-menu-button"
        disabled={!isUserSuspended}
        onClick={() =>
          setUserAction({
            name: "Activate suspended account",
            onAccept: activateSuspendedAccount,
          } as UserAction)
        }
      >
        Activate suspended account
      </button>

      <button
        className="user-details-menu-button"
        disabled={isAccountInactive}
        onClick={() =>
          setUserAction({
            name: "Activate new account",
            onAccept: activateNewAccount,
          } as UserAction)
        }
      >
        Activate new account
      </button>

      <button
        className="user-details-menu-button"
        disabled={isUserSuspended}
        onClick={() => {
          setUserAction({
            name: "Send password reset email",
            onAccept: sendPasswordResetEmail,
          } as UserAction);
        }}
      >
        Reset password
      </button>

      {hasAdminPanelAccess ? (
        <button
          className="user-details-menu-button"
          disabled={isUserSuspended || !hasAdminPanelAccess}
          onClick={() => {
            setUserAction({
              name: "Revoke admini panel access",
              onAccept: revokeAdminPanelAccess,
            } as UserAction);
          }}
        >
          Revoke admin panel access
        </button>
      ) : (
        <button
          className="user-details-menu-button"
          disabled={isUserSuspended || hasAdminPanelAccess}
          onClick={() => {
            setUserAction({
              name: "Grant admin panel access",
              onAccept: grantAdminPanelAccess,
            } as UserAction);
          }}
        >
          Grant admin panel access
        </button>
      )}

      <button
        className="user-details-menu-button"
        disabled={isUserSuspended}
        onClick={() => {
          setUserAction({
            name: !isEnterpriseAccount ? "Grant enterprise access" : "Revoke enterprise access",
            onAccept: toggleEnterprise,
          } as UserAction);
        }}
      >
          {!isEnterpriseAccount ? "Grant enterprise access" : "Revoke enterprise access"}
      </button>

      {isStripeSubscriptionCancelled ? (
        <button
          className="user-details-menu-button"
          disabled={isUserSuspended || !stripeSubscription}
          onClick={() => {
            setUserAction({
              name: "Restore subscription",
              onAccept: restoreStripeSubscription,
            } as UserAction);
          }}
        >
          Restore subscription
        </button>
      ) : (
        <button
          className="user-details-menu-button"
          disabled={isUserSuspended || !stripeSubscription}
          onClick={() => {
            setUserAction({
              name: "Cancel subscription",
              onAccept: cancelStripeSubscription,
            } as UserAction);
          }}
        >
          Cancel subscription
        </button>
      )}
      <button
        className="user-details-menu-button"
        disabled={!userHasPlan}
        onClick={() =>
          setUserAction({
            name: "Set Priority",
            Component: Priority,
            onAccept: savePriority,
          } as UserAction)
        }
      >
        Priority
      </button>
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  isUserSuspended: isUserSuspended(state),
  userHasPlan: userHasPlan(state),
  isAccountInactive: isAccountInactive(state),
  hasAdminPanelAccess: hasAdminPanelAccess(state),
  isStripeSubscriptionCancelled: isStripeSubscriptionCancelled(state),
  stripeSubscription: stripeSubscription(state),
  isEnterpriseAccount: isEnterpriseAccount(state),
  user: userDetails(state),
});

const mapDispatch = {
  setUserAction,
  suspendAccount,
  manageCredits,
  activateSuspendedAccount,
  sendPasswordResetEmail,
  sendAccountActivationEmail,
  grantAdminPanelAccess,
  revokeAdminPanelAccess,
  cancelStripeSubscription,
  restoreStripeSubscription,
  activateNewAccount,
  toggleEnterprise,
  managePriority
}

export default connect(mapState, mapDispatch)(ActionMenu);
